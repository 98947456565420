.content {
  margin: 24px auto;
  min-height: 280px;
  width: 1200px;
  max-width: 1200px;
}

.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #fff;
}

.menu {
  line-height: 64px;
  position: absolute;
  right: 150px;
  border-bottom: none;
}

.header_img {
  position: absolute;
  right: 60px;
  top: 12px;
  /* top: 16px; */
}

.menu_container {
  width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.name {
  font-size: 18px;
  font-weight: 600;
}

.out_container {
  text-align: center;
  height: 434px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.out_desc {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(174, 177, 181, 1);
  padding-top: 30px;
}

.out_title {
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  padding-top: 80px;
}

.sure {
  width: 275px;
  margin-top: 65px;
}

.aggress_sure {
  width: 275px;
}

.menu_item,
.menu_item_active {
  border-bottom: none !important;
}

.menu_item a {
  color: #aeb1b5 !important;
  /* color: '#69707A'; */
}

.menu_item_active_left {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  color: rgba(222, 186, 108, 1);
  background: rgba(222, 186, 108, 1);
  margin-right: 8px;
}

.menu_item_active_color {
  color: #69707a;
}

.menu_item_color,
.menu_item_active_color {
  font-weight: 500;
}

.menu_item_left {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}

.down {
  color: rgb(174, 177, 181);
  position: absolute;
  right: -22px;
  top: 16px;
  font-size: 10px;
  font-weight: 900;
}
