.container {
  text-align: center;
  width: 1220px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aeb1b5;
  line-height: 24px;
  min-height: 800px;
  background-color: #FFFFFF;
}

.container_img {
  display: inline-block;
  width: 120px !important;
  height: 120px !important;
  min-height: 120px !important;
  min-width: 120px !important;
  margin-top: 260px;
}
