@import '~antd/dist/antd.css';

.search_container {
  margin: 40px 0 20px;
}

.table_title {
  font-size: 18px;
  font-weight: 600;
}

.table_container {
  position: relative;
}

.table_right {
  position: absolute;
  right: 26px;
  top: 12px;
}

.table_count {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: right;
}

.title_count_text {
  text-align: right;
}

.container_title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 10px;
}

.text_area_number {
  position: absolute;
  width: 100px;
  right: 10px;
  bottom: 10px;
  text-align: right;
}

.comment {
  display: inline-block;
  max-width: 120px;
  padding-right: 10px;
  overflow: hidden; /*内容超出后隐藏*/
  text-overflow: ellipsis; /* 超出内容显示为省略号*/
  white-space: nowrap; /*文本不进行换行*/
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

.king_normal_input {
  border: none !important;
  border-bottom: 1px solid rgba(190, 197, 211, 1) !important;
  border-radius: 0 !important;
}

.king_normal_input:hover {
  border-bottom: 1px solid rgba(62, 113, 223, 1) !important;
}

.king_normal_input:focus {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(62, 113, 223, 1) !important;
}

.modal-cover .ant-modal-content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
}

.modal-cover-body .ant-modal-content .ant-modal-body {
  padding: 0;
}

.modal-cover .ant-modal-content .ant-modal-body {
  background-color: rgb(255, 255, 255);
}

.hand {
  cursor: pointer;
}

.linear-gradient-gray {
  background: linear-gradient(135deg, #69707a 100%, #8d949e 0%) !important;
  box-shadow: 2px 2px 5px 0px #6b7a90;
  border: none !important;
}

.form_right {
  position: absolute;
  right: 6px;
  bottom: -12px;
}

.header_img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.header_normal_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header_img_card {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.header_img_container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
}

.normal_button {
  width: 220px;
  height: 55px !important;
}

.status-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #deba6c;
}

.little_title {
  width: 122px;
  height: 7px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #aeb1b5;
  line-height: 7px;
  margin-left: 6px;
}

.go_back {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #deba6c;
  line-height: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}

.go_back i {
  vertical-align: inherit;
  font-size: 26px;
}

.red_point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  display: block;
}


.box{
  position: relative;
}

.box label{
  cursor: pointer;
  position: absolute;
  width: 18px;        
  height: 18px;
  top: 0;               
  left: 0;
  background: transparent;        
  border: 1px solid #979797;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 20px;
}

.box label:after{
  opacity: 1;        
  content: '';   
  position: absolute;
  width: 9px;    
  height: 5px;
  background: transparent;    
  top: 3px;
  left: 3px;
  border: 2px solid #575CFF;
  border-top: none;
  border-right: none;    
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}


.box input[type=checkbox]:checked + label:after{
  opacity: 0;        
}


input[type=checkbox]{
  visibility: hidden;
}

.checkbox_container {
  display: flex;
}