.container {
  background: #ffffff;
  margin-top: 30px;
  border-radius: 8px;
  padding: 30px 50px;
  padding-bottom: 80px;
}

.bottom_container {
  width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  line-height: 50px;
}

.desc {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(105, 112, 122, 1);
  line-height: 25px;
}

/* .title {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  line-height: 25px;
  border-bottom: rgba(211, 214, 219, 1) solid 1px;
  padding: 30px;
} */

.card_container {
  width: 480px;
  height: 136px;
  /* height: 120px; */
  border-radius: 4px;
  border: 1px solid rgba(174, 177, 181, 1);
  display: flex;
  margin-top: 20px;
}

.card_img {
  width: 118px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid rgba(174, 177, 181, 1);
  margin-top: 20px;
  margin-right: 20px;
}

.card_title {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(105, 112, 122, 1);
  margin-top: 18px;
}

.card_content {
  font-size: 12px;
  font-family: PingFangSC-Light;
  font-weight: 300;
  color: rgba(105, 112, 122, 1);
  margin-top: 10px;
  width: 260px;
}

.card_description {
  font-size: 12px;
  font-family: PingFangSC-Light;
  font-weight: 300;
  color: rgba(124, 178, 184, 1);
  margin-top: 10px;
  width: 260px;
}

.main {
  /* padding: 30px 50px; */
  position: relative;
}

.radio {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.next {
  display: block;
  width: 220px;
  height: 55px;
  margin: 80px auto 30px;
}

.main_title {
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
}

.main_right {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(214, 181, 108, 1);
  position: absolute;
  right: 60px;
  top: 30px;
}

.bitmap {
  width: 800px;
}

.key_title {
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  margin-top: 30px;
}

.bottom {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  height: 100px;
  line-height: 100px;
  width: 100%;
  padding: 0 30px;
}

.bottom_title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
}

.bottom_right {
  position: absolute;
  right: 30px;
  bottom: 0;
}

.bottom_button {
  margin-right: 50px;
}
